* {
  box-sizing: border-box;
}

body {
  background-color: #ededed;
  font-family: 'Rubik', sans-serif;
  line-height: 1.5;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
}

#app {
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#app>main {
  flex-grow: 1;
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#app>main>h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #3b82f6;
}

#mouth-not-visible {
  align-items: center;
  align-self: center;
  text-align: center;
  width: 50%;
}

#chat-client-container {
  align-self: center;
  width: 90%;
}

#chat-observer-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
}

#requesting-to-join {
  align-self: center;
  text-align: center;
  padding: 2rem;
  font-size: x-large;
  font-weight: 600;
  width: 100%;
}